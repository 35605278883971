<template>

	<el-container class="layout">
		<el-aside class="menuLeft" style="">
			<div class="menuDiv hidden-sm-and-down">
				<Menu></Menu>
			</div>
		</el-aside>
		<el-container>
			<el-main>
				<div class="contentDiv hidden-sm-and-down">
					<Content></Content>
				</div>
				<div class="mobileDiv hidden-md-and-up">
					<Content></Content>
				</div>
			</el-main>
		</el-container>
	</el-container>

<!-- <div class="layout">
		<div class="menuDiv hidden-sm-and-down">
			<Menu></Menu>
		</div>
		<div class="contentDiv hidden-sm-and-down">
			<Content></Content>
		</div>
		<div class="mobileDiv hidden-md-and-up">
			<Content></Content>
		</div>
		
	</div> -->
</template>

<script>
	import Menu from './Menu/IndexView.vue'
	import Content from './Content/IndexView.vue'
	export default {
		components: {
			Menu,
			Content
		},
		created() {
			console.log(111)
		}
	}
</script>

<style lang="less" scoped>
	.layout {
		background-color: #F6F5F4;
		.menuLeft{
			width: unset !important;
			max-width: 200px !important;
			overflow-x: hidden;
			background-color: #ffffff;
			position: sticky;
			top: 0;
			min-height: 100vh;
			height: 100%;
		}
		
		.el-main{
			padding: 0;
		}
		
		.pcDiv {
			// background: linear-gradient(to bottom, rgba(245, 245, 245, 1), rgba(253, 251, 251, 1));
		}

		.el-pager li.active {
			color: #FFB600;
		}

		.contentDiv {
			background-color: rgba(246, 245, 244, 1);
			min-height: 100vh;
		}

	}
</style>