<template>
	<div id="app"><router-view></router-view></div>
</template>

<script>
	export default {
		name: 'app',
		mounted() {
			
			// 在组件挂载后替换meta标签
			this.replaceMetaTags();
		},
		methods: {
			replaceMetaTags() {
				// 移除旧的meta标签
				const existingMeta = document.querySelector('meta[name="viewport"]');
				existingMeta.content ='width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no';
			},
			beforeDestroy() {
				const existingMeta = document.querySelector('meta[name="viewport"]');
				existingMeta.content = 'width=device-width, initial-scale=1';
			}
		}

	}
</script>

<style>
#app {
		
	}
</style>