import Vue from 'vue'
import App from './App.vue'
import router from './router';
import common from '@/common/token'
import infiniteScroll from 'vue-infinite-scroll'
import './plugins/element.js'
import 'normalize.css'
import '@/assets/css/bass.css'
import 'element-ui/lib/theme-chalk/display.css';
import FileSaver from 'file-saver';
import Clipboard from 'v-clipboard'
import * as XLSX from 'xlsx';


function setRem() {
  // 假设设计稿宽度为750px
  const baseWidth = 1920;
  // 获取屏幕宽度
  const screenWidth = document.documentElement.clientWidth || document.body.clientWidth;
  // 计算并设置根元素的字体大小
  if( screenWidth > 1200){
	  document.documentElement.style.fontSize = (screenWidth / baseWidth) * 100 + 'px';
  }else{
	  const baseWidth = 750;
	  document.documentElement.style.fontSize = (screenWidth / baseWidth) * 16 + 'px';
  }
  
}
// 初始化设置
setRem();
// 监听屏幕宽度变化重新设置根字体大小
window.onresize = function() {
  setRem();
};


Vue.config.productionTip = false
Vue.prototype.$api = common
Vue.prototype.$FileSaver = FileSaver
Vue.prototype.$XLSX = XLSX
Vue.use(infiniteScroll)
Vue.use(Clipboard)
new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
