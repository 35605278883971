<template>
	<div class="menuLeftList">
		<el-menu :default-active="$router.path" router class="el-menu-vertical-demo" :collapse-transition="false"
			background-color="#ffffff" text-color="#595959" :collapse="isCollapse" :unique-opened="true">

			<el-menu-item index="/">
				<img v-show="isShow" class="menulogo" :src="menulogo" alt="" />
				<img v-show="!isShow" class="shoulogo" :src="shoulogo" alt="" />
			</el-menu-item>
			<el-menu-item index="/" v-show="indexShow">
				<img :src="shouye">
				<span class="menuWord" slot="title">首页概况</span>
			</el-menu-item>
			<el-submenu index="9" v-show="accountShow">
				<template slot="title">
					<img :src="tianjiazhanghao">
					<span class="menuWord" slot="title">账号管理</span>
				</template>
				<el-menu-item v-show="accountListShow" class="secondMenu" index="/userlist">账户列表</el-menu-item>
			</el-submenu>
			<el-submenu index="10" v-show="wdShow">
				<template slot="title">
					<img :src="mendianguanli" width="16" height="16">
					<span class="menuWord" slot="title">门店管理</span>
				</template>
				<el-menu-item v-show="mdShow" class="secondMenu" index="/network/networklist">门店列表</el-menu-item>
				<el-menu-item v-show="sbShow" class="secondMenu" index="/network/devicelist">设备列表</el-menu-item>
				<el-menu-item v-show="hyShow" class="secondMenu" index="/network/vip">会员列表</el-menu-item>
				<el-menu-item v-show="ddShow" class="secondMenu" index="/network/networkorder">订单管理</el-menu-item>
				<el-menu-item v-show="hxShow" class="secondMenu" index="/network/hxlist">核销管理</el-menu-item>
				<el-menu-item v-show="xckShow" class="secondMenu" index="/network/card">洗车卡管理</el-menu-item>
				<el-menu-item v-show="yhqShow" class="secondMenu" index="/network/coupon">优惠券管理</el-menu-item>
				<el-menu-item class="secondMenu" index="/network/exchange">兑换管理</el-menu-item>
				<el-menu-item class="secondMenu" index="/network/messagelist">短信管理</el-menu-item>
			</el-submenu>
			<el-submenu index="11" v-show="sjShow">
				<template slot="title">
					<img :src="shujvfenxi" width="16" height="16">
					<span class="menuWord" slot="title">数据分析</span>
				</template>
				<el-menu-item v-show="mddataShow" class="secondMenu" index="/dataForm/networkData">门店数据概览</el-menu-item>
				<el-menu-item v-show="userdataShow" class="secondMenu" index="/dataForm/userData">用户行为数据</el-menu-item>
				<el-menu-item v-show="vipdataShow" class="secondMenu" index="/dataForm/vipData">会员数据</el-menu-item>
				<el-menu-item v-show="yxdataShow" class="secondMenu" index="/dataForm/marketData">营销数据</el-menu-item>
				<el-menu-item v-show="kddataShow" class="secondMenu" index="/dataForm/crossStoreData">跨店数据</el-menu-item>
			</el-submenu>
			<!-- <el-menu-item index="4">
				<img :src="shangchengguanli" width="16" height="16">
				<span class="menuWord" slot="title">商城管理</span>
			</el-menu-item> -->
			<!-- <el-menu-item index="5">
				<img :src="yingxiaohuodong" width="16" height="16">
				<span class="menuWord" slot="title">营销活动</span>
			</el-menu-item> -->
			<el-submenu index="/order" v-show="cwShow">
				<template slot="title">
					<img :src="mendianguanli" width="16" height="16">
					<span class="menuWord" slot="title">财务管理</span>
				</template>
				<el-menu-item v-show="csbbShow" class="secondMenu" index="/order/orderList">充值明细</el-menu-item>
				<el-menu-item v-show="csbbShow" class="secondMenu" index="/order/setinlist">跨入明细</el-menu-item>
				<el-menu-item v-show="csbbShow" class="secondMenu" index="/order/setoutlist">跨出明细</el-menu-item>
			</el-submenu>

			<el-submenu index="13" v-show="qtShow">
				<template slot="title">
					<img :src="qitaguanli" width="16" height="16">
					<span class="menuWord" slot="title">其他管理</span>
				</template>
				<el-menu-item v-show="menuShow" class="secondMenu" index="/menulist">菜单管理</el-menu-item>
				<el-menu-item class="secondMenu" index="/notice">赠额管理</el-menu-item>
				<el-menu-item class="secondMenu" index="/network/messagepackage">短信套餐</el-menu-item>
				<el-menu-item v-show="passwordShow" class="secondMenu" index="/userset">修改密码</el-menu-item>
			</el-submenu>

			<el-menu-item class="shouDiv" @click="changeMenu">

				<img class="shouImg" :src="shou" alt="" />
				<span class="shou">收起导航</span>

			</el-menu-item>

		</el-menu>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				shouye: require("@/assets/image/shouye.png"),
				tianjiazhanghao: require("@/assets/image/tianjiazhanghao.png"),
				mendianguanli: require("@/assets/image/mendianguanli.png"),
				shangchengguanli: require("@/assets/image/shangchengguanli.png"),
				yingxiaohuodong: require("@/assets/image/yingxiaohuodong.png"),
				shujvfenxi: require("@/assets/image/shujvfenxi.png"),
				caiwuguanli: require("@/assets/image/caiwuguanli.png"),
				qitaguanli: require("@/assets/image/qitaguanli.png"),
				menulogo: require("@/assets/image/menulogo.png"),
				shoulogo: require("@/assets/image/shoulogo.png"),
				shou: require("@/assets/image/shou.png"),
				isCollapse: false,
				isShow: true,
				userRoleMenuAll:localStorage.getItem("userRoleMenuAll"),
				indexShow:false,
				accountShow:false,
				accountListShow:false,
				wdShow:false,
				sjShow:false,
				cwShow:false,
				qtShow:false,
				mdShow:false,
				sbShow:false,
				hyShow:false,
				ddShow:false,
				hxShow:false,
				xckShow:false,
				yhqShow:false,
				mddataShow:false,
				vipdataShow:false,
				userdataShow:false,
				yxdataShow:false,
				kddataShow:false,
				csbbShow:false,
				menuShow:false,
				passwordShow:false,
			}
		},
		created() {
			this.userMenu()
		},
		methods: {
			changeMenu() {
				this.isCollapse = !this.isCollapse
				this.isShow = !this.isShow
			},
			
			userMenu(){
				const userRoleMenuAll = JSON.parse(this.$data.userRoleMenuAll)
				
				userRoleMenuAll.forEach((column, index)=>{
					if(column.label == "首页概况"){
						this.$data.indexShow = true
					}
					
					if(column.label == "账号管理"){
						this.$data.accountShow = true
						if(column.children){
							column.children.forEach((second,secondkey)=>{
								if(second.label == "账户列表"){
									this.$data.accountListShow = true
								}
							})
						}
					}
					
					if(column.label == "门店管理"){
						this.$data.wdShow = true
						if(column.children){
							column.children.forEach((second,secondkey)=>{
								if(second.label == "门店列表"){
									this.$data.mdShow = true
								}
								
								if(second.label == "设备列表"){
									this.$data.sbShow = true
								}
								
								if(second.label == "会员列表"){
									this.$data.hyShow = true
								}
								
								if(second.label == "订单管理"){
									this.$data.ddShow = true
								}
								
								if(second.label == "核销管理"){
									this.$data.hxShow = true
								}
								
								if(second.label == "洗车卡管理"){
									this.$data.xckShow = true
								}
								
								if(second.label == "优惠券管理"){
									this.$data.yhqShow = true
								}
							})
						}
					}
					
					if(column.label == "数据分析"){
						this.$data.sjShow = true
						if(column.children){
							column.children.forEach((second,secondkey)=>{
								if(second.label == "门店数据概览"){
									this.$data.mddataShow = true
								}
								
								if(second.label == "会员数据"){
									this.$data.vipdataShow = true
								}
								
								if(second.label == "用户行为数据"){
									this.$data.userdataShow = true
								}
								
								if(second.label == "营销数据"){
									this.$data.yxdataShow = true
								}
								
								if(second.label == "跨店数据"){
									this.$data.kddataShow = true
								}
							})
						}
					}
					
					if(column.label == "财务管理"){
						this.$data.cwShow = true
						if(column.children){
							column.children.forEach((second,secondkey)=>{
								if(second.label == "财务报表"){
									this.$data.csbbShow = true
								}
								
							})
						}
					}
					
					if(column.label == "其他管理"){
						this.$data.qtShow = true
						if(column.children){
							column.children.forEach((second,secondkey)=>{
								if(second.label == "菜单管理"){
									this.$data.menuShow = true
								}
								
								if(second.label == "修改密码"){
									this.$data.passwordShow = true
								}
								
							})
						}
					}
					
				
				})
			}
		}
	}
</script>

<style lang="less" scoped>	
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-width: 200px;
	}
	
	.el-menu{
		border: 0;
	}
	
	.el-menu-item:hover,.el-submenu__title:hover{
		background-color: #FFFCE8 !important;
	}
	
	.menuLeftList{
		// position: fixed;
		height: 100%;
		background-color: #ffffff;
	}
	
	.secondMenu{
		margin-left: 6px;
	}


	.el-menu-item.is-active {
		color: #FFB600 !important;
	}

	.el-submenu .el-menu-item {
		min-width: 0 !important;
	}

	.menuWord {
		font-size: 14px;
		margin-left: 10px;
		@media screen and (min-width:992px) and (max-width:1200px) {
			font-size: 14px;
		}
	}

	.menulogo {
		margin-top: 16px;
		margin-bottom: 16px;
		max-width: 136px;
		width: 100%;
	}

	.shouDiv {
		position: fixed !important;
		bottom: 0 !important;
		height: 40px !important;
		line-height: 40px !important;
		border-top: 1px solid #fdf6f6;
		color: #595959;
	}

	.shou {
		margin-left: 10px;
	}

	.shoulogo {
		width: 36px;
		height: 36px;
		margin-left: -8px;
	}
</style>