<template>
	<div class="bodyDiv">
		<!-- 顶部-->
		<div class="header hidden-sm-and-down">
			<div class="divFlex">
				<div class="adminImg">
					<img :src="touXiang" alt="" />
				</div>

				<div class="adminInfo">
					<div class="userPhone">{{userPhone}}</div>
					<div class="userPower">{{userPower}}</div>
				</div>
				<el-dropdown @command="handleCommand">
					<span class="el-dropdown-link">
						<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="logout">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- 内容-->
		<router-view/>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				touXiang: require('@/assets/image/touxiang.png'),
				userPhone: localStorage.getItem("userName"),
				userPower: localStorage.getItem("roleName")
			}
		},
		created() {
			console.log(222)
		},
		methods:{
			handleCommand(command){
				
				if(command == "logout"){
					this.$confirm("即将退出登录，是否继续", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						// 清除localStorage
						localStorage.clear();
						// 清除sessionStorage
						sessionStorage.clear();
						this.$message({
							type: 'success',
							message: '退出成功!即将返回登录页'
						});
						setTimeout(() => {
							this.$router.push('/login')
						}, 1500)
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消退出'
						});
					})
				}
			}
		}

	}
</script>

<style lang="less" scoped>
	.bodyDiv {
		width: 100%;
		/* background-color: rgba(246, 245, 244, 1); */
		overflow-x: hidden;

	}

	.header {
		background-color: #fff;
		width: 100%;
		height: 56px;
	}

	.adminImg {
		img {
			display: block;
		}
	}

	.divFlex {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		height: 100%;
		margin-right: 20px;
	}

	.userPhone {
		margin-left: 12px;
		font-size: 14px;
		font-family: hm_medium;
	}

	.userPower {
		color: #999999;
		margin-left: 12px;
		font-size: 14px;
	}
	
	.navWord{
		cursor: pointer;
	}
</style>