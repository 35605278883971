<template>
	<div>
		<div class="pcDiv hidden-sm-and-down">
			<el-row :gutter="10">
				<div class="dashboardDiv">
					<el-col :span="16">
						<div class="indexLeft">
							<div class="indexLeftToday comDiv maxWidth">
								<div>
									<p class="indexDivTitle">实时数据（今日）</p>
								</div>
								<div class="indexLeftTodayBjColor indexFlex">
									<div class="todayInfo">
										<p class="todayNumber">{{todayUser}}</p>
										<p class="toadyWord">新增会员</p>
									</div>
									<div class="todayInfo">
										<p class="todayNumber">{{todayPay}}</p>
										<p class="toadyWord">充值订单</p>
									</div>
									<div class="todayInfo">
										<p class="todayNumber">{{todayPayNum}}</p>
										<p class="toadyWord">充值金额</p>
									</div>
									<div class="todayInfo">
										<p class="todayNumber">{{todayUse}}</p>
										<p class="toadyWord">消费订单</p>
									</div>
									<div class="todayInfo">
										<p class="todayNumber">{{todayUseNum}}</p>
										<p class="toadyWord">消费金额</p>
									</div>
									<div class="todayInfo">
										<p class="todayNumber">{{todayOrder}}</p>
										<p class="toadyWord">进行中的订单</p>
									</div>
									<div class="todayInfo">
										<p class="todayNumber">{{todayDevice}}</p>
										<p class="toadyWord">设备数</p>
									</div>
								</div>
							</div>
							<div class="indexLeftChange comDiv maxWidth">
								<div>
									<p class="indexDivTitle">转化数据</p>
								</div>
								<div class="indexLeftChangeInfo indexFlex">
									<div class="todayInfo">
										<p class="indexLeftChangeInfoTitle">转化率</p>
										<p class="indexLeftChangeInfoNumber">{{zhuanhuaVip.vip_lv}}%</p>
										<!-- <div class="indexLeftChangeInfoRatio indexFlex">
											<p class="indexLeftChangeInfoRatioWord">较昨日</p>
											<p class="">
												<img class="indexLeftChangeInfoRatioImg" :src="redImg" alt="" />
												<span class="indexLeftChangeInfoRatioDetails">2.23%</span>
											</p>
										</div> -->
									</div>
									<div class="todayInfo">
										<p class="indexLeftChangeInfoTitle">注册数</p>
										<p class="indexLeftChangeInfoNumber">{{todayUser}}</p>
										<!-- <div class="indexLeftChangeInfoRatio indexFlex">
											<p class="indexLeftChangeInfoRatioWord">较昨日</p>
											<p class="">
												<img class="indexLeftChangeInfoRatioImg" :src="greenImg" alt="" />
												<span class="indexLeftChangeInfoRatioDetails">2.23%</span>
											</p>
										</div> -->
									</div>
									<div class="todayInfo">
										<p class="indexLeftChangeInfoTitle">总会员数</p>
										<p class="indexLeftChangeInfoNumber">{{zhuanhuaVip.total_user}}</p>
										<!-- <div class="indexLeftChangeInfoRatio indexFlex">
											<p class="indexLeftChangeInfoRatioWord">较昨日</p>
											<p class="">
												<img class="indexLeftChangeInfoRatioImg" :src="redImg" alt="" />
												<span class="indexLeftChangeInfoRatioDetails">2.23%</span>
											</p>
										</div> -->
									</div>
									<div class="todayInfo">
										<p class="indexLeftChangeInfoTitle">有效会员</p>
										<p class="indexLeftChangeInfoNumber">{{zhuanhuaVip.user_youxiao}}</p>
										<!-- <div class="indexLeftChangeInfoRatio indexFlex">
											<p class="indexLeftChangeInfoRatioWord">较昨日</p>
											<p class="">
												<img class="indexLeftChangeInfoRatioImg" :src="greenImg" alt="" />
												<span class="indexLeftChangeInfoRatioDetails">2.23%</span>
											</p>
										</div> -->
									</div>
									<div class="todayInfo">
										<p class="indexLeftChangeInfoTitle">无效会员</p>
										<p class="indexLeftChangeInfoNumber">{{zhuanhuaVip.user_wuxiao}}</p>
										<!-- <div class="indexLeftChangeInfoRatio indexFlex">
											<p class="indexLeftChangeInfoRatioWord">较昨日</p>
											<p class="">
												<img class="indexLeftChangeInfoRatioImg" :src="redImg" alt="" />
												<span class="indexLeftChangeInfoRatioDetails">2.23%</span>
											</p>
										</div> -->
									</div>
								</div>
							</div>
							<div class="indexLeftMenu comDiv maxWidth">
								<div>
									<p class="indexDivTitle">快速入口</p>
								</div>
								<div class="indexLeftMenu indexFlex">
									<div class="todayInfo textCentet">
										<router-link to="/network/networklist">
											<img class="goToMenu" :src="mendian" alt="" />
											<p class="indexLeftMenuWord">门店列表</p>
										</router-link>
									</div>
									<div class="todayInfo textCentet" @click="goToDevice">
										<img class="goToMenu" :src="shebei" alt="" />
										<p class="indexLeftMenuWord">设备列表</p>
									</div>
									<div class="todayInfo textCentet" @click="goToOrder">
										<img class="goToMenu" :src="dingdan" alt="" />
										<p class="indexLeftMenuWord">订单查询</p>
									</div>
									<div class="todayInfo textCentet" @click="goToPackage">
										<img class="goToMenu" :src="chongzhi" alt="" />
										<p class="indexLeftMenuWord">洗车卡管理</p>
									</div>
									<div class="todayInfo textCentet" @click="goToVip">
										<img class="goToMenu" :src="huiyuan" alt="" />
										<p class="indexLeftMenuWord">会员管理</p>
									</div>
									<div class="todayInfo textCentet" @click="goToAdmin">
										<img class="goToMenu" :src="tianjia" alt="" />
										<p class="indexLeftMenuWord">核销管理</p>
									</div>
								</div>
							</div>
							<div class="indexLeftEcharts comDiv maxWidth">
								<div>
									<p class="indexDivTitle">趋势看板（周）</p>
								</div>
								<div class="indexEchartsDiv ">
									<div class="indexEcharts indexFlex">
										<div>
											<p class="indexEchartsTitle">充值趋势</p>
											<MyEcharts id="echarts1" :option="chart1Option"
												style="width: 6rem;height: 400px;">
											</MyEcharts>
										</div>

										<div style="overflow: auto;">
											<p class="indexEchartsTitle">充值排名</p>
											<el-table :data="chongzhiPaiMing" style="width: 100%">
												<el-table-column prop="no" label="排名" width="80">
												</el-table-column>
												<el-table-column prop="vip_user_name" label="用户" width="180">
												</el-table-column>
												<el-table-column prop="num" label="充值次数" width="100">
												</el-table-column>
												<el-table-column prop="order_sum_money" label="总额度" width="100">
												</el-table-column>
											</el-table>
										</div>
									</div>

									<div class="indexEcharts indexFlex">
										<div>
											<p class="indexEchartsTitle">消费趋势</p>
											<MyEcharts id="echarts2" :option="chart2Option"
												style="width: 6rem;height: 400px;">
											</MyEcharts>
										</div>

										<div class="" style="overflow: auto;">
											<p class="indexEchartsTitle">消费排名</p>
											<el-table :data="xiaofeiPaiMing" style="width: 100%">
												<el-table-column prop="no" label="排名" width="80">
												</el-table-column>
												<el-table-column prop="vip_user_name" label="用户" width="180">
												</el-table-column>
												<el-table-column prop="num" label="消费次数" width="100">
												</el-table-column>
												<el-table-column prop="order_sum_money" label="总额度" width="100">
												</el-table-column>
											</el-table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="indexRight">
							<div class="indexRightNetWorkListDiv comDiv">
								<div class="indexRightNetWorkList indexFlex">
									<div class="indexFlex" style="align-items: center;">
										<div class="netWorkImg hidden-md-and-down">
											<img :src="defaultImg" alt="" />
										</div>
										<div>
											<p class="netWorkName">{{networkName}}</p>
											<p class="netWorkDefaultName">叮当蜂自助洗车</p>
										</div>
									</div>
									<div class="indexFlex" style="align-items: center;">
										<el-dropdown class="" @command="changeNetWork">
											<el-button class="changeNetWorkButton">
												<i class="el-icon-arrow-down ">切换</i>
											</el-button>
											<el-dropdown-menu lot="dropdown">
												<el-dropdown-item command="all">全部门店</el-dropdown-item>
												<el-dropdown-item v-for="item in options" :key='item.network_only' :command="item">{{item.network_name}}</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</div>
								</div>
								<el-divider></el-divider>
								<div class="indexRightNetWorlDataDiv indexFlex">
									<div>
										<p class="dataNumber">{{vipAll}}</p>
										<p class="dataWord">会员总数</p>
									</div>
									<div>
										<p class="dataNumber">{{tradeAll}}</p>
										<p class="dataWord">营业总额</p>
									</div>
									<div>
										<p class="dataNumber">{{incomeAll}}</p>
										<p class="dataWord">分成收入</p>
									</div>
								</div>
								<div>
									<button class="userInfoBtn" @click="goToUserset">账号信息</button>
								</div>
							</div>
							<div class="indexRightDeviceStatusDiv comDiv">
								<div>
									<p class="indexDivTitle">设备运行状态</p>
								</div>
								<div class="">
									<div v-for="item in deviceList" :key="item.bianhao"
										class="indexRightDeviceStatus indexFlex">
										<div v-if="item.statusWord == '离线中'" class="lixian">
											<span>{{item.statusWord}}</span>
										</div>
										<div v-else-if="item.statusWord == '空闲中'" class="kongxian">
											<span>{{item.statusWord}}</span>
										</div>
										<div v-else="item.statusWord == '运行中'" class="yunxing">
											<span>{{item.statusWord}}</span>
										</div>
										<div class="deviceInfo">
											<span class="mRight">设备编号：{{item.bianhao}}</span>
											<span>({{item.gongwei}})</span>
										</div>
									</div>
								</div>
							</div>
							<div class="indexRightpackageDiv comDiv">
								<p class="indexEchartsTitle">套餐销量排行</p>
								<el-table :data="taoCanPaiHang" style="width: 100%">
									<el-table-column prop="no" label="排名" width="60">
									</el-table-column>
									<el-table-column prop="source" label="渠道" width="100">
									</el-table-column>
									<el-table-column prop="name" label="套餐名" width="180">
									</el-table-column>
									<el-table-column prop="num" label="销售额" width="100">
									</el-table-column>
								</el-table>
							</div>
							<div class="indexRightpackageDiv comDiv">
								<p class="indexEchartsTitle">门店营业额排行</p>
								<el-table :data="menDianPaiHang" style="width: 100%">
									<el-table-column prop="no" label="排名" width="80">
									</el-table-column>
									<el-table-column prop="network_name" label="门店名称" width="260">
									</el-table-column>
									<el-table-column prop="order_sum_money" label="营业额" width="100">
									</el-table-column>
								</el-table>
							</div>
						</div>
					</el-col>
				</div>
			</el-row>

		</div>

		<div class="mobileDiv hidden-md-and-up">
			<div class="mobileUseDiv">
				<div class="mobileFlex topLogo spaceBetween itemCenter">
					<div class="mobileTopLog">
						<img :src="mobile.topLogo" alt="" />
					</div>
					<div class="mobileChangNetWorkName">
						<el-dropdown class="mobileChangNetWorkName oneLine" trigger="click" @command="changeNetWork">
							<div class="el-dropdown-link mobileFlex">
								<p class="mobileChangNetWorkName oneLine">{{networkName}}</p><i class="el-icon-caret-bottom iconFont"></i>
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="item in options" :key="item.network_only"
									:command="item">{{item.network_name}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>

				<div class="mobileAdminInfo">
					<div class="mobileFlex spaceBetween itemCenter mobileIndexHeader">
						<div class="mobileFlex  itemCenter">
							<div class="mobileAdminAvatar">
								<img :src="mobile.adminAvatar" alt="" />
							</div>
							<div class="mobileAdminMsg">
								<p class="mobileAdminName">{{username}}</p>
								<p class="mobileAdminNetwork oneLine">当前网点：{{networkName}}</p>
							</div>
						</div>
						<div>
							<el-dropdown trigger="click" @command="adminAction">
								<i class="el-icon-caret-bottom mobileAdminAction"></i>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-show="passwordShow" command="changePassword">修改密码</el-dropdown-item>
									<el-dropdown-item command="logout">退出登录</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
					<div class="mobileAdminNetWork mobileFlex spaceBetween itemCenter">
						<div class="">
							<div class="mobileAdminNetWorkDiv">
								<p class="mobileAdminNetWorkNum">{{todayPayNum}}</p>
								<p class="mobileAdminNetWorkWord">今日营业额</p>
							</div>
							<div class="mobileAdminNetWorkDiv">
								<p class="mobileAdminNetWorkNum">{{mobileDataYueChong}}</p>
								<p class="mobileAdminNetWorkWord">本月营业额</p>
							</div>
						</div>
						<div class="">
							<div class="mobileAdminNetWorkDiv">
								<p class="mobileAdminNetWorkNum">{{todayUser}}</p>
								<p class="mobileAdminNetWorkWord">今日新增会员</p>
							</div>
							<div class="mobileAdminNetWorkDiv">
								<p class="mobileAdminNetWorkNum">{{mobileDataAllVip}}</p>
								<p class="mobileAdminNetWorkWord">累计总会员</p>
							</div>
						</div>
						<div class="">
							<div class="mobileAdminNetWorkDiv">
								<p class="mobileAdminNetWorkNum">{{todayUseNum}}</p>
								<p class="mobileAdminNetWorkWord">今日消费</p>
							</div>
							<div class="mobileAdminNetWorkDiv">
								<p class="mobileAdminNetWorkNum">{{mobileDataYueUse}}</p>
								<p class="mobileAdminNetWorkWord">本月消费</p>
							</div>
						</div>
						<div class="">
							<div class="mobileAdminNetWorkDiv">
								<p class="mobileAdminNetWorkNum">{{mobileDataUseGive}}</p>
								<p class="mobileAdminNetWorkWord">剩余赠送</p>
							</div>
							<div class="mobileAdminNetWorkDiv">
								<p class="mobileAdminNetWorkNum">{{devAll}}</p>
								<p class="mobileAdminNetWorkWord">设备总数</p>
							</div>
						</div>
					</div>
				</div>
			
				<div class="mobileWorkTitle">常用功能</div>
				<div class="mobileMenu">
					<div class="mobileFlex spaceBetween">
						<div v-show="ddShow" class="mobileMenuOrder" @click="goToOrder">
							<p class="mobileMenuWord">订单管理</p>
							<p class="mobileMenuDesc">所有订单类型的查询搜索</p>
							<div class="mobileMenuTopImg">
								<img :src="mobile.orderManage" alt="" />
							</div>
						</div>
						<div v-show="chongzhiShow" class="mobileMenuMoney" @click="goToCwData">
							<p class="mobileMenuWord">财务报表</p>
							<p class="mobileMenuDesc">门店收益与分账</p>
							<div class="mobileMenuTopImg">
								<img :src="mobile.moneyManage" alt="" />
							</div>
						</div>
					</div>
					<div class="mobileMenuList mobileFlex spaceBetween itemCenter">
						<div v-show="mdShow" class="mobileMenuListDiv" @click="goToNetWorkListShow">
							<img class="mobileMenuListImg" :src="mobile.menu1" alt="" />
							<p class="mobileMenuListWord">门店设置</p>
						</div>
						<div v-show="xckShow" class="mobileMenuListDiv" @click="goToPackage">
							<img class="mobileMenuListImg" :src="mobile.menu2" alt="" />
							<p class="mobileMenuListWord">洗车卡</p>
						</div>
						<div v-show="yhqShow" class="mobileMenuListDiv" @click="goToCounp">
							<img class="mobileMenuListImg" :src="mobile.menu3" alt="" />
							<p class="mobileMenuListWord">洗车券</p>
						</div>
						<div v-show="dhShow" class="mobileMenuListDiv" @click="goToExchange">
							<img class="mobileMenuListImg" :src="mobile.menu4" alt="" />
							<p class="mobileMenuListWord">兑换管理</p>
						</div>
					</div>
					<div class="mobileMenuList mobileFlex spaceBetween itemCenter">
						<div v-show="sbShow" class="mobileMenuListDiv" @click="goToDevice">
							<img class="mobileMenuListImg" :src="mobile.menu5" alt="" />
							<p class="mobileMenuListWord">设备管理</p>
						</div>
						<div v-show="hyShow" class="mobileMenuListDiv" @click="goToVip">
							<img class="mobileMenuListImg" :src="mobile.menu6" alt="" />
							<p class="mobileMenuListWord">会员管理</p>
						</div>
						<div v-show="duanxianShow" class="mobileMenuListDiv" @click="goToMes">
							<img class="mobileMenuListImg" :src="mobile.menu7" alt="" />
							<p class="mobileMenuListWord">短信管理</p>
						</div>
						<div v-show="shujvShow" class="mobileMenuListDiv" @click="goToSj">
							<img class="mobileMenuListImg" :src="mobile.menu8" alt="" />
							<p class="mobileMenuListWord">数据分析</p>
						</div>
					</div>
					<div class="mobileMenuList mobileFlex spaceBetween itemCenter">
						<div v-show="zengsongShow" class="mobileMenuListDiv" @click="goToYx">
							<img class="mobileMenuListImg" :src="mobile.menu10" alt="" />
							<p class="mobileMenuListWord">营销活动</p>
						</div>
						<div v-show="zengsongShow" class="mobileMenuListDiv" @click="goToGive">
							<img class="mobileMenuListImg" :src="mobile.menu9" alt="" />
							<p class="mobileMenuListWord">赠额管理</p>
						</div>
						<div class="mobileMenuListDiv" >
							<!-- <img class="mobileMenuListImg" :src="mobile.menu6" alt="" /> -->
							<p class="mobileMenuListWord">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</p>
						</div>
						<div class="mobileMenuListDiv" >
							<!-- <img class="mobileMenuListImg" :src="mobile.menu7" alt="" /> -->
							<p class="mobileMenuListWord">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</p>
						</div>
					</div>
				</div>
				<div class="mobileWorkTitle">设备状态</div>
				<div class="mobileDeviceRun mobileFlex spaceBetween itemCenter" @click="goToRun">
					<div class="mobileDeviceIcon mobileFlex itemCenter">
						<img class="mobileDeviceImgIcon" :src="mobile.runDevice" alt="">
						<span class="mobileIndexDeviceWord">运行</span>
					</div>
					<div class="mobileDeviceIconWord mobileFlex itemCenter">
						<span class="mobileIndexDeviceNum">{{deviceRun}}</span>
						<i class="mobileIndexDeviceIcon el-icon-arrow-right"></i>
					</div>
				</div>
				<div class="mobileDeviceStop mobileFlex spaceBetween itemCenter" @click="goToNoRun">
					<div class="mobileDeviceIcon mobileFlex itemCenter">
						<img class="mobileDeviceImgIcon" :src="mobile.stopDevice" alt="">
						<span class="mobileIndexDeviceWord">空闲</span>
					</div>
					<div class="mobileDeviceIconWord mobileFlex itemCenter">
						<span class="mobileIndexDeviceNum">{{deviceKx}}</span>
						<i class="mobileIndexDeviceIcon el-icon-arrow-right"></i>
					</div>
				</div>
				<div class="mobileDeviceNotOnLine mobileFlex spaceBetween itemCenter" @click="goToLiXian">
					<div class="mobileDeviceIcon mobileFlex itemCenter">
						<img class="mobileDeviceImgIcon" :src="mobile.notOnLineDevice" alt="">
						<span class="mobileIndexDeviceWord">离线</span>
					</div>
					<div class="mobileDeviceIconWord mobileFlex itemCenter">
						<span class="mobileIndexDeviceNum">{{deviceLx}}</span>
						<i class="mobileIndexDeviceIcon el-icon-arrow-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import MyEcharts from '@/components/MyEcharts.vue'
	import {
		NetWorkList,
		netWorkInfoByOnly,
		allNotice,
		indexTotal,
		indexNetWork,
		indexTaoCan,
		indexYingYe,
		indexDevList,
		indexTodayInfo,
		indexChongZhi,
		indexXiaoFei,
		indexZhuanHua,
		indexQuShi
	} from "@/request/api"
	export default {
		data() {
			return {
				username: localStorage.getItem("userName"),
				accountShow: false,
				userRoleMenuAll:localStorage.getItem("userRoleMenuAll"),
				mdShow:false,
				xckShow:false,
				yhqShow:false,
				passwordShow:false,
				dhShow:false,
				sbShow:false,
				hyShow:false,
				duanxianShow:false,
				shujvShow:false,
				zengsongShow:false,
				ddShow:false,
				chongzhiShow:false,
				deviceKx:'',
				deviceRun:'',
				deviceLx:'',
				greenImg: require("@/assets/image/greenxia.png"),
				redImg: require("@/assets/image/redshang.png"),
				mendian: require("@/assets/image/mendianguanli1.png"),
				shebei: require("@/assets/image/shebeiguanli.png"),
				dingdan: require("@/assets/image/dingdanchaxun.png"),
				chongzhi: require("@/assets/image/chongzhitaocan.png"),
				huiyuan: require("@/assets/image/huiyuanguanli.png"),
				tianjia: require("@/assets/image/hx.png"),
				netWorkImg: require("@/assets/image/changenetwork.png"),
				defaultImg: require("@/assets/image/userdefault.png"),
				todayUser: '',
				todayPay: '',
				todayPayNum: '',
				todayUse: '',
				todayUseNum: '',
				todayOrder: '',
				todayDevice: '',
				vipAll: "",
				tradeAll: '',
				incomeAll: '',
				indexNetWork: "all",
				smsNotice: true,
				cardNotice: true,
				chart1Option: {
					tooltip: {
						trigger: 'axis'
					},
					legend: {},
					toolbox: {
						show: true,

					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value} 元'
						}
					},
					series: {
						name: "本周",
						data: [],
						type: 'line',
						smooth: true,
						markPoint: {
							data: [{
									type: 'max',
									name: 'Max'
								},
								{
									type: 'min',
									name: 'Min'
								}
							]
						},
						markLine: {
							data: [{
								type: 'average',
								name: 'Avg'
							}]
						}
					}
				},
				chart2Option: {

					tooltip: {
						trigger: 'axis'
					},
					legend: {},
					toolbox: {
						show: true,

					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value} 元'
						}
					},
					series: {
						name: "本周",
						data: [],
						type: 'line',
						smooth: true,
						markPoint: {
							data: [{
									type: 'max',
									name: 'Max'
								},
								{
									type: 'min',
									name: 'Min'
								}
							]
						},
						markLine: {
							data: [{
								type: 'average',
								name: 'Avg'
							}]
						}
					},
				},
				chongzhiPaiMing: [],
				xiaofeiPaiMing:[],
				taoCanPaiHang: [],
				menDianPaiHang: [],
				deviceList: [],
				options: '',
				networkName: "全部门店",
				networkOnly: "all",
				zhuanhuaVip:'',
				networkAddress: "",
				mobileDataAllGive:'',
				mobileDataUseGive:'',
				mobileDataYueGive:'',
				mobileDataYueChong:'',
				mobileDataAllVip:'',
				mobileDataYueUse:'',
				devAll:'',
				mobile: {
					topLogo: require("@/assets/mobile/image/index/logoTitle.png"),
					adminAvatar: require("@/assets/mobile/image/index/txIcon.png"),
					orderManage: require("@/assets/mobile/image/index/orderIcon.png"),
					moneyManage: require("@/assets/mobile/image/index/caiwuIcon.png"),
					menu1:require("@/assets/mobile/image/index/wdIcon.png"),
					menu2:require("@/assets/mobile/image/index/cardIcon.png"),
					menu3:require("@/assets/mobile/image/index/quanIcon.png"),
					menu4:require("@/assets/mobile/image/index/dhIcon.png"),
					menu5:require("@/assets/mobile/image/index/sbIcon.png"),
					menu6:require("@/assets/mobile/image/index/vipIcon.png"),
					menu7:require("@/assets/mobile/image/index/mesIcon.png"),
					menu8:require("@/assets/mobile/image/index/sjIcon.png"),
					menu9:require("@/assets/mobile/image/index/giveIcon.png"),
					menu10:require("@/assets/mobile/image/index/yingxiaoIcon.png"),
					runDevice:require("@/assets/mobile/image/index/runIcon.png"),
					stopDevice:require("@/assets/mobile/image/index/kxIcon.png"),
					notOnLineDevice:require("@/assets/mobile/image/index/lixian.png"),
					todayAll:99999.00,
					todayVip:99999.00,
					todayUse:99999.00,
					todayGive:99999.00,
					monthAll:99999.00,
					monthVip:99999.00,
					monthUse:99999.00,
					monthGive:99999.00,
				}
			}

		},
		// {
		// 		name: '上周',
		// 		type: 'line',
		// 		data: [100, -20, 20, 50, 30, 20, 80],
		// 		smooth: true,
		// 		markPoint: {
		// 			data: [{
		// 				name: '周最低',
		// 				value: -2,
		// 				xAxis: 1,
		// 				yAxis: -1.5
		// 			}]
		// 		},
		// 		markLine: {
		// 			data: [{
		// 					type: 'average',
		// 					name: 'Avg'
		// 				},
		// 				[{
		// 						symbol: 'none',
		// 						x: '90%',
		// 						yAxis: 'max'
		// 					},
		// 					{
		// 						symbol: 'circle',
		// 						label: {
		// 							position: 'start',
		// 							formatter: 'Max'
		// 						},
		// 						type: 'max',
		// 						name: '最高点'
		// 					}
		// 				]
		// 			]
		// 		}
		// 	}
		
		created() {
			this.userMenu()
			this.getNetWork()
			this.getNotice()
			this.getIndexTotal()
			this.getIndexTaoCan()
			this.getIndexYingYe()
			this.getIndexDevList()
			this.getIndexTodayInfo()
			this.getIndexChongZhi()
			this.getIndexXiaoFei()
			this.getIndexZhuanHua()
			this.getIndexQuShi()
		},

		methods: {
			
			
			userMenu() {
				const userRoleMenuAll = JSON.parse(this.$data.userRoleMenuAll)
				userRoleMenuAll.forEach((column, index) => {
					if(column.label == "门店管理"){
						this.$data.wdShow = true
						if(column.children){
							column.children.forEach((second,secondkey)=>{
								if(second.label == "门店列表"){
									this.$data.mdShow = true
								}
								
								if(second.label == "设备列表"){
									this.$data.sbShow = true
								}
								
								if(second.label == "会员列表"){
									this.$data.hyShow = true
								}
								
								if(second.label == "订单管理"){
									this.$data.ddShow = true
								}
								
								if(second.label == "核销管理"){
									this.$data.hxShow = true
								}
								
								if(second.label == "洗车卡管理"){
									this.$data.xckShow = true
								}
								
								if(second.label == "洗车券管理"){
									this.$data.yhqShow = true
								}
								
								if(second.label == "兑换管理"){
									this.$data.dhShow = true
								}
								
								if(second.label == "短信管理"){
									this.$data.duanxianShow = true
								}
							})
						}
					}
					
					if(column.label == "数据分析"){
						this.$data.sjShow = true
						if(column.children){
							column.children.forEach((second,secondkey)=>{
								if(second.label == "门店数据概览"){
									this.$data.shujvShow = true
									
								}
								
							})
						}
					}
					
					if(column.label == "财务管理"){
						this.$data.cwShow = true
						if(column.children){
							column.children.forEach((second,secondkey)=>{
								if(second.label == "充值明细"){
									this.$data.chongzhiShow = true
								}
								
							})
						}
					}
					
					if(column.label == "其他管理"){
						this.$data.qtShow = true
						if(column.children){
							column.children.forEach((second,secondkey)=>{
								
								if(second.label == "修改密码"){
									this.$data.passwordShow = true
								}
								
								if(second.label == "赠额管理"){
									this.$data.zengsongShow = true
								}
							})
						}
					}
				})
			},

			getIndexQuShi(){
				
				var formData = new FormData()
				formData.append("wd", this.$data.networkOnly)
				indexQuShi(formData).then(res => {
					if (res.code == 20000) {
						this.$data.chart1Option.xAxis = res.data.chongzhi.riqi
						this.$data.chart1Option.series.data = res.data.chongzhi.num
						this.$data.chart2Option.xAxis = res.data.xiaofei.riqi
						this.$data.chart2Option.series.data = res.data.xiaofei.num
					}
				})
			},
			getNetWork() {
				var formData = new FormData()
				formData.append("limit", "99999")
				formData.append("page", "1")
				NetWorkList(formData).then(res => {
					if (res.code == 20000) {
						this.$data.options = res.data.data
					}
				})
			},

			getNotice() {
				allNotice().then(res => {
					if (res.code == 20000) {
						if (res.data.simCard) {
							this.$confirm(res.data.simCard.count, res.data.simCard.title, {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.$router.push({
									path: '/network/devicelist'
								})
							}).catch(() => {

							});
							return false
						}

						if (res.data.sms) {
							this.$confirm(res.data.sms.count, res.data.sms.title, {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.$router.push({
									path: '/network/messagelist'
								})
							}).catch(() => {

							});
							return false
						}

					}
				})

			},

			getSmsNotice(title, message) {
				this.$notify({
					title: title,
					message: message,
					type: 'warning',
					duration: 0
				})
			},

			changeEchartsByPay() {
				this.$data.mobile.btnChecked = true
			},
			changeEchartsByUse() {
				this.$data.mobile.btnChecked = false
			},
			openMenu() {
				this.$data.mobile.dialog = true
			},
			handleClose() {
				this.$data.mobile.dialog = false
			},
			cancelForm() {
				this.$data.mobile.loading = false;
				this.$data.mobile.dialog = false;
			},
			goToNetWorkListShow() {
				this.$router.push({
					path: '/network/networklist'
				})
			},
			goToDevice() {
				this.$router.push({
					path: '/network/devicelist'
				})
			},
			goToOrder() {
				this.$router.push('/network/networkorder')
			},
			goToPackage() {
				this.$router.push('/network/card')
			},
			goToVip() {
				this.$router.push('/network/vip')
			},
			goToAdmin() {
				this.$router.push('/network/hxlist')
			},
			goToUserset() {
				this.$router.push('/userset')
			},
			goToNetworkData() {
				this.$router.push('/dataForm/networkData')
			},
			goToUserData() {
				this.$router.push('/dataForm/userData')
			},
			goToVipData() {
				this.$router.push('/dataForm/vipData')
			},
			goToYxData() {
				this.$router.push('/dataForm/marketData')
			},
			goToKdData() {
				this.$router.push('/dataForm/crossStoreData')
			},
			goToCwData() {
				this.$router.push('/order/orderList')
			},
			goToHx() {
				this.$router.push('network/hxlist')
			},
			goToCounp() {
				this.$router.push('/network/coupon')
			},
			goToExchange() {
				this.$router.push('/network/exchange')
			},
			goToMes() {
				this.$router.push('/network/messagelist')
			},
			goToSj() {
				this.$router.push('/dataForm/networkData')
			},
			
			goToGive(){
				this.$router.push('/giveindex')
			},
			
			goToYx(){
				this.$router.push('/activenetworkList')
			},
			
			goToRun(val){
				this.$router.push({
					path: '/rundevice',
					query: {
						networkId: this.$data.networkOnly,
						type:1
					}
				})
			},
			
			goToNoRun(val){
				this.$router.push({
					path: '/norundevice',
					query: {
						networkId: this.$data.networkOnly,
						type:2
					}
				})
			},
			
			goToLiXian(val){
				this.$router.push({
					path: '/noonlinedevice',
					query: {
						networkId: this.$data.networkOnly,
						type:3
					}
				})
			},

			adminAction(command) {
				if (command == "logout") {
					this.$confirm("即将退出登录，是否继续", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						// 清除localStorage
						localStorage.clear();
						// 清除sessionStorage
						sessionStorage.clear();
						this.$message({
							type: 'success',
							message: '退出成功!即将返回登录页'
						});
						setTimeout(() => {
							this.$router.push('/login')
						}, 1500)
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消退出'
						});
					})
				}
				
				if(command == "changePassword"){
					this.goToUserset()
				}
			},
			changeNetWork(command) {
				if(command == "all"){
					this.$data.networkOnly = "all"
					this.$data.networkName = "全部网点"
				}else{
					this.$data.networkName = command.network_name
					this.$data.networkOnly = command.network_only
				}
				
				this.getNotice()
				this.getIndexTotal()
				this.getIndexTaoCan()
				this.getIndexYingYe()
				this.getIndexDevList()
				this.getIndexTodayInfo()
				this.getIndexChongZhi()
				this.getIndexXiaoFei()
				this.getIndexZhuanHua()
			},
			
			getIndexTaoCan(){
				var formData = new FormData()				
				formData.append("wd",this.$data.networkOnly)
				indexTaoCan(formData).then(res=>{
					if (res.code == 20000) {
						this.$data.taoCanPaiHang = res.data
					}
				})
			},
			
			getIndexTotal() {
				var formData = new FormData()
				formData.append("wd", this.$data.networkOnly)
				indexTotal(formData).then(res => {
					if (res.code == 20000) {
						this.$data.vipAll = res.data.vip_total_num
						this.$data.tradeAll = res.data.order_num
						this.$data.incomeAll = res.data.fencheng_num
					}
				})
			},
			
			getIndexYingYe(){
				var formData = new FormData()
				formData.append("wd", this.$data.networkOnly)
				indexYingYe(formData).then(res => {
					if (res.code == 20000) {
						this.$data.menDianPaiHang = res.data
					}
				})
			},
			
			getIndexDevList(){
				var formData = new FormData()
				formData.append("wd", this.$data.networkOnly)
				indexDevList(formData).then(res => {
					if (res.code == 20000) {
						this.$data.deviceList = res.data.list
						this.$data.deviceKx = res.data.kx
						this.$data.deviceRun = res.data.run
						this.$data.deviceLx = res.data.lx
					}
				})
			},
			
			getIndexTodayInfo(){
				var formData = new FormData()
				formData.append("wd", this.$data.networkOnly)
				indexTodayInfo(formData).then(res => {
					if (res.code == 20000) {
						this.$data.todayUser = res.data.vip_today_num
						this.$data.todayPay = res.data.order_num_chongzhi
						this.$data.todayPayNum = res.data.order_sum_chongzhi
						this.$data.todayUse = res.data.order_num_xiaofei
						this.$data.todayUseNum = res.data.order_sum_xiaofei
						this.$data.todayOrder = res.data.run_num
						this.$data.todayDevice = res.data.dev_num
						this.$data.mobileDataAllGive = res.data.give_amount_sum
						this.$data.mobileDataUseGive = res.data.give_amount_sum
						this.$data.mobileDataYueUse = res.data.order_sum_yue_xiaofei
						this.$data.mobileDataYueChong = res.data.order_sum_yue_chongzhi
						this.$data.mobileDataAllVip = res.data.vip_total_num
						this.$data.devAll = res.data.dev_num
						
					}
				})
			},
			
			getIndexChongZhi(){
				var formData = new FormData()
				formData.append("wd", this.$data.networkOnly)
				indexChongZhi(formData).then(res => {
					if (res.code == 20000) {
						
						this.$data.chongzhiPaiMing = res.data
					}
				})
			},
			
			getIndexXiaoFei(){
				var formData = new FormData()
				formData.append("wd", this.$data.networkOnly)
				indexXiaoFei(formData).then(res => {
					if (res.code == 20000) {
						this.$data.xiaofeiPaiMing = res.data
					}
				})
			},
			
			getIndexZhuanHua(){
				var formData = new FormData()
				formData.append("wd", this.$data.networkOnly)
				indexZhuanHua(formData).then(res => {
					if (res.code == 20000) {
						this.$data.zhuanhuaVip = res.data
					}
				})
				
			}
		},
		components: {
			MyEcharts
		}
	}
</script>

<style lang="less" scoped>
	.dashboardDiv {
		margin: 32px;
		font-size: 16px;

		.goToMenu {
			max-width: 54px;
			width: 100%
		}

		.indexFlex {
			display: flex;
		}

		.maxWidth {
			max-width: 1093px;
		}

		.comDiv {
			padding: 0.26rem;
			margin-bottom: 0.21rem;
			background-color: #ffffff;
			border-radius: 10px;
		}

		.indexDivTitle {
			font-size: 0.21rem;
			margin-bottom: 0.26rem;
			font-family: hm_bold;

			@media screen and (min-width:992px) and (max-width:1200px) {
				font-size: 18px;
			}
		}

		.todayInfo {
			text-align: left;
			padding: 32px 0;
		}

		.textCentet {
			text-align: center;
		}

		.indexLeftToday {
			.indexLeftTodayBjColor {
				flex-direction: row;
				justify-content: space-around;
				align-items: center;
				background: linear-gradient(to top right, rgba(255, 253, 247, 0.9), rgba(255, 253, 245, 0.22), rgba(255, 252, 247, 1));
			}

			.todayNumber {
				font-size: 0.27rem;
				font-family: hm_bold;
				color: rgba(32, 32, 32, 1);

				@media screen and (min-width:992px) and (max-width:1200px) {
					font-size: 22px;
				}
			}

			.toadyWord {
				font-size: 0.19rem;
				font-family: hm_regular;
				color: rgba(32, 32, 32, 0.6);
				margin-top: 5px;

				@media screen and (min-width:992px) and (max-width:1200px) {
					font-size: 14px;
				}
			}
		}

		.indexLeftChangeInfo {
			flex-direction: row;
			justify-content: space-between;

			.todayInfo {
				padding: 0;
			}


			.todayInfo img {
				/* width: 0.54rem;
				height: 0.54rem; */
			}

			.indexLeftChangeInfoTitle {
				font-size: 0.19rem;
				font-family: hm_regular;
				color: rgba(32, 32, 32, 0.6);

				@media screen and (min-width:992px) and (max-width:1200px) {
					font-size: 16px;
				}
			}

			.indexLeftChangeInfoNumber {
				font-size: 0.42rem;
				font-family: hm_medium;
				color: rgba(32, 32, 32, 1);
				margin-top: 16px;
				margin-bottom: 10px;

				@media screen and (min-width:992px) and (max-width:1200px) {
					font-size: 23px;
				}
			}

			.indexLeftChangeInfoRatioImg {
				margin-left: 10px;
				margin-right: 5px;
			}

			.indexLeftChangeInfoRatio {
				color: rgba(32, 32, 32, 0.4);
				font-family: hm_regular;
				align-items: center;
			}

			.indexLeftChangeInfoRatioWord {
				font-size: 0.16rem;

				@media screen and (min-width:992px) and (max-width:1200px) {
					font-size: 13px;
				}
			}
		}

		.indexLeftMenu {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.todayInfo {
				padding: 0;
			}
		}

		.indexLeftEcharts {

			.indexEcharts {
				flex-direction: row;
				justify-content: space-between;
			}

			.indexEchartsTitle {
				font-size: 0.19rem;
				color: rgba(32, 32, 32, 1);
				font-family: hm_regular;

				@media screen and (min-width:992px) and (max-width:1200px) {
					font-size: 16px;
				}
			}

			th>.cell {
				font-size: 0.16rem;
				color: rgba(32, 32, 32, 1);
				font-family: hm_regular;
				text-align: left;

				@media screen and (min-width:992px) and (max-width:1200px) {
					font-size: 14px;
				}
			}

			td>.cell {
				font-size: 0.16rem;
				color: rgba(32, 32, 32, 0.6);
				font-family: hm_regular;
				text-align: left;

				@media screen and (min-width:992px) and (max-width:1200px) {
					font-size: 14px;
				}
			}


			::v-deep .el-table--border th.el-table__cell,
			::v-deep .el-table td.el-table__cell {
				border-bottom: none !important;
			}

			::v-deep .el-table--border .el-table__cell {
				border-right: none !important;
			}

			::v-deep .el-table--group,
			.el-table--border {
				border: none !important;
			}

			.el-table::before {
				height: 0;
			}
		}

		.indexLeftMenuWord {
			color: rgba(32, 32, 32, 0.6);
			margin-top: 10px;
			font-size: 0.19rem;
			font-family: hm_regular;

		}

		.indexRight {
			margin-left: 17px;
			max-width: 510px;
			width: 100%;

			.indexRightNetWorkList {
				flex-direction: row;
				justify-content: space-between;

				.netWorkImg {
					width: 100%;
					max-width: 64px;
					margin-right: 10px;
				}

				.netWorkName {
					font-size: 0.21rem;
					color: rgba(56, 56, 56, 1);
					font-family: hm_bold;
					margin-top: 4px;
					margin-bottom: 6px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					@media screen and (min-width:992px) and (max-width:1200px) {
						font-size: 14px;

					}
				}

				.netWorkDefaultName {
					font-size: 0.16rem;
					color: rgba(252, 151, 0, 1);
					font-family: hm_regular;

					@media screen and (min-width:992px) and (max-width:1200px) {
						font-size: 12px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.changeNetWorkByPc {
					min-width: 120px;
					width: 100%;
					font-size: 16px;
					color: rgba(32, 32, 32, 1);
					font-family: hm_regular;

				}

				.changeNetWorkButton {
					border: 0;
				}

				.btnInStyle {
					align-items: center;
				}
			}

			.indexRightNetWorlDataDiv {
				justify-content: space-between;
				text-align: center;

				.dataNumber {
					font-family: hm_medium;
					color: #000000;
					font-size: 0.26rem;
				}

				.dataWord {
					font-size: 16px;
					font-family: hm_regular;
					color: rgba(0, 0, 0, 0.4);
					margin-top: 15px;
				}
			}

			.userInfoBtn {
				width: 100%;
				margin-top: 16px;
				border: 0;
				background-color: rgba(255, 182, 0, 1);
				color: rgba(255, 255, 255, 0.9);
				border-radius: 6px;
				padding: 13px 0;
				font-size: 18px;
			}

			.indexRightDeviceStatusDiv {
				max-height: 413px;
				min-height: 4.13rem;
				overflow: auto;

				.indexRightDeviceStatus {
					align-items: center;
					padding-left: 17px;
					font-size: 0.18rem;
					font-family: hm_regular;
					color: #202020;
					margin-bottom: 20px;

					@media screen and (min-width:992px) and (max-width:1200px) {
						font-size: 14px;
					}
				}

				.lixian {
					background-color: rgba(255, 87, 51, 0.31);
					color: #FF5733;
					padding: 5px 10px;
					font-size: 0.16rem;
					font-family: hm_regular;
					border-radius: 5px;
					margin-right: 10px;

					@media screen and (min-width:992px) and (max-width:1200px) {
						font-size: 14px;
					}
				}

				.kongxian {
					background-color: rgba(67, 207, 124, 0.1);
					color: rgba(67, 207, 124, 1);
					padding: 5px 10px;
					font-size: 16px;
					font-family: hm_regular;
					border-radius: 5px;
					margin-right: 10px;

					@media screen and (min-width:992px) and (max-width:1200px) {
						font-size: 14px;
					}
				}

				.yunxing {
					background-color: rgba(255, 182, 0, 0.1);
					color: #FFB600;
					padding: 5px 10px;
					font-size: 16px;
					font-family: hm_regular;
					border-radius: 5px;
					margin-right: 10px;

					@media screen and (min-width:992px) and (max-width:1200px) {
						font-size: 14px;
					}
				}

				.mRight {
					margin-right: 15px;
				}
			}

			.indexRightpackageDiv {
				.indexEchartsTitle {
					font-size: 0.19rem;
					color: rgba(32, 32, 32, 1);
					font-family: hm_regular;

					@media screen and (min-width:992px) and (max-width:1200px) {
						font-size: 14px;
					}
				}

				th>.cell {
					font-size: 0.16rem;
					color: rgba(32, 32, 32, 1);
					font-family: hm_regular;

					text-align: left;

					@media screen and (min-width:992px) and (max-width:1200px) {
						font-size: 14px;
					}
				}

				td>.cell {
					font-size: 0.16rem;
					color: rgba(32, 32, 32, 0.6);
					font-family: hm_regular;

					text-align: left;

					@media screen and (min-width:992px) and (max-width:1200px) {
						font-size: 14px;
					}
				}


				::v-deep .el-table--border th.el-table__cell,
				::v-deep .el-table td.el-table__cell {
					border-bottom: none !important;
				}

				::v-deep .el-table--border .el-table__cell {
					border-right: none !important;
				}

				::v-deep .el-table--group,
				.el-table--border {
					border: none !important;
				}

				.el-table::before {
					height: 0;
				}
			}

		}
	}

	.mobileTopLog>img {
		width: 21.9375rem
	}

	.topLogo {
		padding: 3rem 0;
	}

	.mobileChangNetWorkName {
		color: #383838;
		font-family: hm_medium;
		max-width: 16.625rem;
		font-size:1.75rem;
	}

	.mobileAdminInfo {
		width: 100%;
		background: linear-gradient(to bottom, #3F3F3F, #323232);
		border-radius: 0.75rem;
	}
	
	.mobileIndexHeader{
		padding-top: 2.5rem;
		width: 38.75rem;
		margin: 	0 auto;
	}
	
	.mobileAdminAvatar{
		width: 7rem;
		height: 7rem;
		border: 2px solid #ffffff;
		border-radius: 50%;
	}
	
	.mobileAdminAvatar > img{
		width: 100%;
	}
	
	.mobileAdminMsg{
		color:#ffffff;
		margin-left: 1.5rem;
		max-width: 22.5rem;
	}
	
	.mobileAdminName{
		font-size: 2.125rem;
		font-family: hm_medium;
		
	}
	
	.mobileAdminNetwork{
		font-size: 1.25rem;
		font-family: hm_regular;
		margin-top: 1rem;
		
	}
	
	.mobileAdminAction{
		color: #ffffff;
		font-size: 2rem;
	}
	
	.mobileAdminNetWork{
		width: 38.875rem;
		margin: 3.75rem auto 0;
	}
	
	.mobileAdminNetWorkNum{
		font-size: 1.75rem;
		font-family: hm_medium;
		color: #ffffff;
	}
	
	.mobileAdminNetWorkWord{
		font-size: 1.25rem;
		margin-top: 1rem;
		color: #ffffff;
		font-family: hm_regular;
		margin-bottom: 2.5625rem;
	}
	
	.mobileWorkTitle{
		margin-top: 2.75rem;
		margin-bottom: 1.125rem;
		font-family: hm_medium;
		font-size: 2.125rem;
		color: #383838;
	}
	
	.mobileMenuOrder{
		background-color: #FFB600;
		color: #ffffff;
		width: 28rem;
		height: 18.6875rem;
		border-radius: 1.125rem;
	}
	
	.mobileMenuWord{
		font-size: 2.125rem;
		font-family: hm_bold;
		margin-top: 2.875rem;
		margin-left: 2rem;
		color: #ffffff;
	}
	
	.mobileMenuDesc{
		margin-top: 0.8125rem;
		font-size: 1.375rem;
		font-family: hm_regular;
		color: #ffffff;
		margin-left: 2rem;
	}
	
	.mobileMenuTopImg{
		text-align: right;
		margin-top: 2.4375rem;
	}
	
	.mobileMenuTopImg > img{
		width: 6.6875rem;
		margin-right: 2rem;
	}
	
	.mobileMenuMoney{
		width: 13.8125rem;
		height: 18.6875rem;
		background-color: #157CF8;
		border-radius: 1.125rem;
	}
	
	.mobileMenuList{
		text-align: center;
		margin-top: 2rem;
	}
	
	.mobileMenuListImg{
		width: 3.25rem;
	}
	
	.mobileMenuListWord{
		font-size: 1.625rem;
		font-family: hm_medium;
		color: #000000;
		margin-top: 1.25rem;
	}
	
	.mobileDeviceRun{
		border:1px solid #FFB600;
		width:42.875rem;
		height: 8rem;
		background-color: #FFF8EB;
		border-radius: 1.125rem;
		margin-bottom: 1.25rem;
	}
	
	.mobileDeviceStop{
		border:1px solid #19C280;
		width:42.875rem;
		height: 8rem;
		background-color: #EBFCF6;
		border-radius: 1.125rem;
		margin-bottom: 1.25rem;
	}
	
	.mobileDeviceImgIcon{
		width: 3.25rem;
		margin-left: 2.125rem;
	}
	
	.mobileIndexDeviceNum{
		margin-right: 1rem;
	}
	
	.mobileIndexDeviceWord,.mobileIndexDeviceNum{
		font-family: hm_medium;
		font-size: 2.375rem;
		color: #000000;
		margin-left: 1.5625rem;
	}
	
	.mobileIndexDeviceIcon{
		margin-right: 1.375rem;
		font-size: 2rem;
		font-weight: bold;
	}
	
	.iconFont{
		font-size: 1.5rem;
	} 
	
	.mobileDeviceNotOnLine{
		border:1px solid #C21919;
		width:42.875rem;
		height: 8rem;
		background-color: #FCEBEB;
		border-radius: 1.125rem;
	}
</style>
