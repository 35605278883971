<template>
	<div ref="chartContainer" :id="id" :option="option" class="myEcharts">
	</div>
</template>

<script>
	import * as echarts from 'echarts';

	export default {
		props: {
			id: {
				type: String,
				required: true
			},
			option: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				chart: null,
			}
		},
		mounted() {
			this.initChart();
			window.addEventListener('resize', this.resizeChart);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resizeChart);
			if (this.chart) {
				this.chart.dispose();
			}
		},
		methods: {
			initChart() {
				
				this.chart = echarts.init(this.$refs.chartContainer);
				this.chart.setOption(this.option);
			},
			resizeChart() {
				if (this.chart) {
					this.chart.resize();
				}
			}
		},
		watch: {
			option: {
				deep: true,
				handler(newOption) {
					if (this.chart) {
						this.chart.setOption(newOption);
					}
				}
			}
		}
	}
</script>

<style>

</style>