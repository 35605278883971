<template>

	<el-row class="loginDiv">
		<div class="hidden-sm-and-down pcDiv">
			<el-col :span="12">
				<div class="loginImg">
					<div class="title">
						<img :src="titleImg" alt="">
					</div>
					<div class="bodyDivImg">
						<img :src="bodyImg" alt="">
					</div>
				</div>
			</el-col>
			<el-col :span="10">
				<div class="loginForm">
					<p class="loginTitle">欢迎登录叮当峰</p>
					<el-form ref="form" :model="form" :rules="rules">
						<el-form-item>
							<div class="ccsFlex loginInput">
								<img class="loginInputImg" :src="username" alt="">
								<el-input class="loginInputWord" placeholder="请输入账号" v-model="form.name"></el-input>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="ccsFlex loginInput">
								<img class="loginInputImg" :src="passwordImg" alt="">
								<el-input class="loginInputWord" type="password" placeholder="请输入密码" v-model="form.pass"></el-input>
							</div>
						</el-form-item>
						<el-form-item>
							<el-button class="loginBtn" type="primary" @click="onSubmit('form')"
								style="color: #ffffff;">登录</el-button>
						</el-form-item>
						<div>
							<p class="loginWord">点击「登录」表示已阅读并同意<span>服务条款</span></p>
						</div>
					</el-form>
				</div>
			</el-col>
			<el-col :span="2"></el-col>
		</div>


		<div class="hidden-md-and-up mdDiv">
			<el-col :span="24">
				<div class="title mdLogo">
					<img :src="mobilelogo" alt="">
				</div>
			</el-col>

			<el-col :span="24" class="formDiv">
				<div class="mobileWelcome">
					<img :src="mobileWelcome" alt="">
				</div>
				<div class="loginForm">
					<!-- <p class="loginTitle">欢迎登录叮当峰</p> -->
					<el-form ref="form" :model="form" :rules="rules">
						<el-form-item>
							<div class="ccsFlex loginInput">
								<!-- <img class="loginInputImg" :src="username" alt=""> -->
								<el-input class="loginInputWord" placeholder="请输入账号" v-model="form.name"></el-input>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="ccsFlex loginInput">
								<!-- <img class="loginInputImg" :src="passwordImg" alt=""> -->
								<el-input class="loginInputWord" type="password" placeholder="请输入密码" v-model="form.pass"></el-input>
							</div>
						</el-form-item>
						<el-form-item>
							<el-button class="loginBtn" type="primary" @click="onSubmit('form')"
								style="color: #ffffff;">登录</el-button>
						</el-form-item>
						<div>
							<p class="loginWord">点击「登录」表示已阅读并同意<span>服务条款</span></p>
						</div>
					</el-form>
				</div>
			</el-col>

		</div>


	</el-row>



</template>

<script>
	import {AdminLogin} from '@/request/api'
	export default {
		data() {
			return {
				titleImg: require('@/assets/image/title.png'),
				bodyImg: require('@/assets/image/login.png'),
				username: require('@/assets/image/username.png'),
				passwordImg: require('@/assets/image/password.png'),
				mobilelogo:require('@/assets/mobile/image/index/mobilelogo.png'),
				mobileWelcome:require('@/assets/mobile/image/index/mobileWelcome.png'),
				form: {
					name: '',
					pass: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入账号或手机号',
						trigger: 'blur'
					}],
					pass:[{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			starLoading() {
			    // 创建一个 loading 实例并赋值给 loading 变量
			    let loading = this.$loading({
			        text: "加载中", // 设置 loading 文本为 "加载中"
			        spinner: "el-icon-loading", // 使用 Element UI 提供的加载图标
			        background: "rgba(0, 0, 0, 0.7)", // 设置 loading 遮罩层背景色为半透明黑色
			        target: document.querySelector("body"), // 将 loading 遮罩层挂载到页面 body 元素上
			    });
			    // 返回一个包含关闭 loading 遮罩层方法的对象
			    return {
			        // 方法用于关闭 loading 遮罩层
			        closeLoading: () => {
			            loading.close(); // 调用 loading 实例的 close 方法关闭遮罩层
			        }
			    };
			},
			onSubmit(formname) {
				this.$refs[formname].validate((valid) => {
					if (valid) {
						const formData = new FormData();
						this.loadingInstance = this.starLoading()
						formData.append("userAccount", this.$data.form.name)
						formData.append("userPwd", this.$data.form.pass)
						formData.append("userPhone", this.$data.form.name)
						AdminLogin(formData).then(res=>{
							if(res.code == 20000){
								localStorage.setItem("token",res.data.token)
								localStorage.setItem("userName",res.data.userName)
								localStorage.setItem("userOnly",res.data.userOnly)
								localStorage.setItem("account",res.data.account)
								localStorage.setItem("roleName",res.data.roleName)
								localStorage.setItem("userRoleMenuAll",JSON.stringify(res.data.userRoleMenuAll))
								this.$nextTick(()=>{
									this.loadingInstance.closeLoading();
								})
								this.$message({
									message:res.message,
									type:'success'
								})

								setTimeout(()=>{
									this.$router.push({path:"/"})
								},2000)
							}else{
								this.$nextTick(()=>{
									this.loadingInstance.closeLoading();
								})
								this.$message({
									message:res.message,
									type:'error'
								})
							}
						})
					}else{
						this.$nextTick(()=>{
							this.loadingInstance.closeLoading();
						})
						this.$message({
							message:"登录失败，账号或密码错误",
							type:"error"
						})
					}
				})
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.loginDiv {
		background: linear-gradient(to bottom, rgba(245, 245, 245, 1), rgba(253, 251, 251, 1));
		height: 100vh;

		.pcDiv {
			.ccsFlex {
				display: flex;
				justify-content: flex-start;
			}

			.loginImg {
				margin-left: 0.8rem;
			}

			.title {
				padding-top: 20px;
			}

			.title>img {
				max-width: 264px;
				width: 100%;
			}

			.bodyDivImg>img {
				max-width: 845px;
				width: 100%;
			}

			.loginForm {
				margin-top: 1.4rem;
				margin-left: 0.8rem;
				background-color: #ffffff;
				border-radius: 32px;
				padding: 0.2rem 0.4rem;
				min-width: 345px;
				@media screen and(min-width:750px) and (max-width:1200px) {
					margin-top: 200px;
					.loginTitle{
						font-size: 24px;
					}
					
					.loginInput{
						margin: 20px auto 0;
						padding: 3px 0;
						
					}
					
					.el-input__inner{
						font-size: 16px;
					}
					
					.loginInputImg{
						width: 30px;
						height: 30px;
					}
					
					.loginBtn{
						font-size: 20px;
					}
					
					.loginWord{
						font-size: 14px;
						padding-bottom: 16px;
					}
				}
			}

			.el-form-item {
				margin-bottom: 0 !important;
				font-size: 24px;
			}

			.loginTitle {
				font-size: 0.4rem;
				color: #191919;
				padding-top: 15px;
				margin-left: 10px;
				font-family: hm_medium;
				
			}

			.loginInputImg {
				width: 0.3rem;
				height: 0.3rem;
				margin-left: 0.32rem;
				margin-right: 0.21rem;
				min-width: 20px;
			}

			.loginInput {
				border: 1px solid #D9D9D9;
				margin: 40px auto 0;
				border-radius: 5px;
				align-items: center;
			}

			.loginInputWord>input {
				border: 0;
				border-color: transparent;
				// font-size: 0.24rem;
				display: block;
				height: 0.6rem;
			}

			.loginBtn {
				display: block !important;
				margin: 40px auto 0 !important;
				background-color: #FFB600 !important;
				border-color: #FFB600 !important;
				font-size: 0.28rem ;
				padding: 0.2rem 1.2rem !important;
			}

			.loginWord {
				font-size: 0.18rem;
				color: #8C8C8C;
				letter-spacing: 1px;
				text-align: center;
				margin-top: 30px;
			}

			.loginWord>span {
				color: #FFB600;
			}
		}
	
		.mdDiv {
			background-color: #ffffff;
			height: 100vh;
			.ccsFlex {
				display: flex;
				justify-content: flex-start;
			}
			
			.loginForm {
				margin-top: 1.4rem;
				margin-left: 0.8rem;
				// background-color: #ffffff;
				border-radius: 32px;
				padding: 0.2rem 0.4rem;
				min-width: 345px;
				margin-right: 0.8rem;
			}
			
			.el-form-item {
				margin-bottom: 0 !important;
				font-size: 24px;
			}
			
			.loginTitle {
				font-size: 36px;
				color: #191919;
				padding-top: 15px;
				margin-left: 10px;
				font-family: hm_medium;
				text-align: center;
				@media screen and (max-width:750px) {
					font-size: 24px;
					text-align: center;
				}
			}
			
			.loginInputImg {
				width: 0.6rem;
				margin-left: 0.32rem;
				margin-right: 0.21rem;
				min-width: 20px;
			}
			
			.loginInput {
				border: 1px solid #D9D9D9;
				margin: 40px auto 0;
				border-radius: 5px;
				align-items: center;
				@media screen and (max-width:750px) {
					border-radius: 0.5rem;
					margin: 2.5rem auto 0;
				}
				
			}
			
			.loginInputWord>input {
				border: 0;
				border-color: transparent;
				font-size: 22px;
				display: block;
				@media screen and (max-width:750px) {
					font-size: 16px;
				}
			}
			
			.loginBtn {
				display: block !important;
				margin: 40px auto 0 !important;
				background-color: #FFB600 !important;
				border-color: #FFB600 !important;
				font-size: 24px;
				padding: 1.5rem 8rem !important;
				@media screen and (max-width:750px) {
					font-size: 16px;
				}
			}
			
			.loginWord {
				font-size: 20px;
				color: #8C8C8C;
				letter-spacing: 1px;
				text-align: center;
				margin-top: 32px;
				padding-bottom: 32px;
				width: 40rem;
				margin: 32px auto;
				@media screen and (max-width:750px) {
					font-size: 12px;
					margin-top: 2rem;
					padding-bottom: 2rem;
					margin-left: 0;
				}
				
			}
			
			.loginWord>span {
				color: #FFB600;
			}
			
			.mdLogo {
				text-align: center;
				padding-top: 10rem;
				margin-bottom: 7.5rem;
			}
			
			.mdLogo > img{
				width: 20.8125rem;
				max-width: 100%;
			}
			
			.formDiv{
				// margin-left: 25px;
				    width: 40rem;
				    margin: 0 auto;
					float: none;
			}
			
			.mobileWelcome{
				// margin-top: 10rem;
				margin-left: 10px;
			}
			
			.mobileWelcome > img{
				max-width: 100%;
				width: 15.5rem;
			}
			
			.el-form-item__content{
				width: 40rem;
			}
		}
	}

	.el-message{
		min-width: auto !important;
		width: auto;
	}
</style>